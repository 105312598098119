######
# Wundery namespace
#
# Examples
#
#   Wundery.cart.configure({ storeId: "123" })
#   Wundery.cart.inject()
#
class @Wundery

  # Wundery main namespace
  #
  constructor: () ->

    @_options =
      loggingEnabled: false,
      apiEndpoint: "https://api.wundery.com",
      checkoutEndpoint: "https://checkout.wundery.com"

    @cart =

      _products: {}

      # Holds the current checkout
      _checkout: null

      # Holds injection state
      _injected: false

      # ////////////////////////////////////////////////////////////////////////
      #
      # Public methods
      #
      # ////////////////////////////////////////////////////////////////////////

      # Enables cart configuration
      #
      # E.g. the storeId is a required parameter.
      configure: (options) =>
        if options && options.storeId
          @cart.storeId = options.storeId
        @cart

      post: (resource, payload, callback) =>
        @_request "POST",
          @_apiEndpoint("storefront_api/v1/#{resource}"),
          payload,
          callback

      get: (resource, callback) =>
        @_request "GET",
          @_apiEndpoint("storefront_api/v1/#{resource}"),
          null,
          callback

      inject: =>
        unless @cart._isInjected()
          @cart._draw()
          window.onload = () =>
            @cart._fetchCheckout(() => @cart._draw(@cart.getCheckout()))

      discover: =>
        for product in @cart._discover("product", document)
          variants = @cart._discoverVariants(product)
          @cart._registerProduct(product)

          for option in @cart._discover("option", product)
            @cart._registerOption(product, option)
            @cart._registerSelection(product, option, option.options[option.selectedIndex])

            option.onchange = (event) =>
              option = event.target || event.srcElement
              characteristic = option.options[option.selectedIndex]
              product = @cart._discoverParent("product", option)
              variants = @cart._discoverVariants(product)
              @cart._registerSelection(product, option, characteristic)
              @cart._handleSelection(product, variants)

          for sender in @cart._discover("add", product)
            sender.addEventListener "click",
              (event) =>
                clickedElement = event.target || event.srcElement
                clickedElement.disabled = true
                adding = @cart._discover("adding", clickedElement)
                if adding[0]
                  adding[0].style.display = "inherit"
                product = @cart._discoverParent("product", clickedElement)
                if @cart._hasOptions(product)
                  characteristicsIds = @cart._getSelectedCharacteristicIds(product)
                  variants = @cart._discoverVariants(product)
                  variantId = @cart._findVariantByCharacteristics(characteristicsIds, variants)
                  if variantId
                    @cart.addVariant({ id: variantId }, clickedElement)
                else
                  variant = @cart._getDefaultVariantData(product)
                  if variant
                    @cart.addVariant({ id: variant.id }, clickedElement)
                  else
                    @_logError("Could not find default variant")

          @cart._handleSelection(product, variants)

      setup: () =>
        @cart.inject()
        @cart.discover()
        @cart

      addVariant: (options, clickedElement) =>
        if options && options.id

          # Callback to be executed when the cart has a checkout
          perform = =>
            @cart.post "checkout_items", {
              checkout_id: @cart.getCheckout().id,
              variant_id: options.id,
              quantity: 1
            }, (status, response) =>
              if status == 201
                @cart._setCheckout(JSON.parse(response).checkout)
                @cart._draw(JSON.parse(response).checkout)
                if clickedElement
                  clickedElement.disabled = false
              else
                @_logError "Request error while POSTing a new checkout item (#{status})"


          # Ensure a checkout is present before adding the variant
          if @cart.hasCheckout()
            perform()
          else
            @cart._fetchCheckout(perform)

        else
          @_logError "'options.id' must be set"
          null

      getCheckout: =>
        @cart._checkout

      hasCheckout: =>
        @cart._checkout != null && @cart._checkout != undefined

      # ////////////////////////////////////////////////////////////////////////
      #
      # Internal methods
      #
      # ////////////////////////////////////////////////////////////////////////

      _setCheckout: (checkout) =>
        @cart._checkout = checkout

      _fetchCheckout: (callback) =>
        id = @cart._getPersistedCheckoutId()
        if id
          @cart.get "checkouts/#{id}",
            (status, response) =>

              if status == 200

                checkout = JSON.parse(response)
                if checkout.finished == true
                  @cart._unsetPersistedCheckoutId()
                  @cart._setCheckout(null)
                  @cart._fetchCheckout(callback)
                else
                  @cart._setCheckout(checkout)
                  callback()

              # Checkout could not be found,
              # unset existing one and get a new one.
              else if status == 404
                @cart._unsetPersistedCheckoutId()
                @cart._setCheckout(null)
                @cart._fetchCheckout(callback)
              else
                @_logError "Error while fetching checkout #{id} (#{status})"

        else
          @cart.post "checkouts", { store_id: @cart.storeId },
            (status, response) =>
              if status == 201
                @cart._setCheckout(JSON.parse(response))
                @cart._setPersistedCheckoutId(@cart.getCheckout().id)
                callback()
              else
                @_logError "Request error while POSTing a new checkout (#{status})"

      _setPersistedCheckoutId: (id) =>
        @_setCookie(@cart._cookieName(), id)

      _unsetPersistedCheckoutId: () =>
        @_unsetCookie(@cart._cookieName())

      _styles: ->
        """
          div.wundery-cart {
            position: fixed;
            top: 10px;
            right: 10px;
          }
          div.wundery-cart > a.wundery-cart-box {
            padding: 6px 10px;
            border-radius: 3px;
            background-color: white;
            border: 1px solid #d9dddd;
            box-shadow: 0 2px 2px rgba(0,0,0,0.05);
            font-family: Helvetica, Arial, sans-serif;
            font-size: 11px;
            text-decoration: none;
            display: block;
            cursor: pointer;
          }
          div.wundery-cart > a.wundery-cart-box:hover {
            background-color: #f5f5f5;
            border-color: #d9cccc;
          }
          div.wundery-cart > a.wundery-cart-box .wundery-cart-label {
            font-weight: bold;
            color: #515e66;
          }
          div.wundery-cart > a.wundery-cart-box .wundery-cart-total {
            color: #999;
          }
          div.wundery-cart > a.wundery-cart-box .wundery-cart-numitems {
            background-color: #333;
            padding: 0 3px;
            border-radius: 2px;
            font-weight: bold;
            color: white;
          }
        """

      _injectStyles: =>
        styles = document.createElement("style")
        styles.innerHTML = @cart._styles()
        document.head.appendChild(styles)

      _isInjected: =>
        @cart._injected == true

      _setInjected: =>
        @cart._injected = true

      _isInjected: =>
        @cart._injected == true

      _draw: (checkout) =>
        if @cart._isInjected()
          box = document.getElementsByClassName("wundery-cart")[0]
        else
          @cart._injectStyles()
          box = document.createElement("div")
          klass = document.createAttribute("class")
          klass.value = "wundery-cart"
          box.setAttributeNode(klass)
          document.body.appendChild(box)
          @cart._setInjected()

        checkoutId = checkout && checkout.id || "_"
        total = checkout && checkout.goods_total_gross_formatted || "€0.00"
        numItems = checkout && checkout.checkout_items_count || "0"
        url = @_checkoutEndpoint("#/#{checkoutId}/cart")
        box.innerHTML = """
          <a class="wundery-cart-box" href="#{url}">
            <span class="wundery-cart-label">WARENKORB</span>
            <span class="wundery-cart-total">#{total || "€0.00"}</span>
            <span class="wundery-cart-numitems">#{numItems || 0}</span>
          </a>
        """

      _registerProduct: (product) =>
        @cart._products[@cart._discoverValue(product, "product")] = {}

      _registerOption: (product, option) =>
        @cart._products[@cart._discoverValue(product, "product")][@cart._discoverValue(option, "option")] = {}

      _findVariantByCharacteristics: (ids, variants) =>
        for variant in variants
          variantId = variant.id || null
          characteristicIds = variant.characteristic_ids || []
          if variantId && characteristicIds.length > 0
            if @_arrayEqual(characteristicIds, ids)
              return variantId
        null

      _getSelectedCharacteristicIds: (product) =>
        ids = []
        for optionId, characteristicId of @cart._products[@cart._discoverValue(product, "product")]
          ids.push(characteristicId) if characteristicId != ""
        ids

      _disableAllSenders: (product) =>
        for sender in @cart._discover("add", product)
          sender.disabled = true

      _enableAllSenders: (product) =>
        for sender in @cart._discover("add", product)
          sender.disabled = false

      _showNoSelection: (product) =>
        for element in @cart._discover("noselection", product)
          element.style.display = "inherit"

      _hideNoSelection: (product) =>
        for element in @cart._discover("noselection", product)
          element.style.display = "none"

      _showNotAvailable: (product) =>
        for element in @cart._discover("notavailable", product)
          element.style.display = "inherit"

      _hideNotAvailable: (product) =>
        for element in @cart._discover("notavailable", product)
          element.style.display = "none"

      _hasNoSelection: (product) =>
        @cart._getSelectedCharacteristicIds(product).length == 0

      _hidePrices: (product) =>
        for element in @cart._discover("price-gross", product)
          element.style.display = "none"
        for element in @cart._discover("price-net", product)
          element.style.display = "none"
        for element in @cart._discover("base-price-description", product)
          element.style.display = "none"
        for element in @cart._discover("weight", product)
          element.style.display = "none"

      _showPrices: (product, variant) =>
        for element in @cart._discover("price-gross", product)
          element.innerHTML = variant.inherited_price_gross_formatted || ""
          element.style.display = "inherit"
        for element in @cart._discover("price-net", product)
          element.innerHTML = variant.inherited_price_net_formatted || ""
          element.style.display = "inherit"
        for element in @cart._discover("base-price-description", product)
          element.innerHTML = variant.inherited_base_price_description || ""
          element.style.display = "inherit"
        for element in @cart._discover("weight", product)
          element.innerHTML = variant.inherited_weight_auto_formatted || ""
          element.style.display = "inherit"

      _hasOptions: (product) =>
        i = 0
        for k, v of @cart._products[@cart._discoverValue(product, "product")]
          i++
        i > 0

      _handleSelection: (product, variants) =>
        @cart._hideNotAvailable(product)
        @cart._hidePrices(product)
        @cart._hideNoSelection(product)
        @cart._disableAllSenders(product)

        if @cart._hasOptions(product)
          if @cart._hasNoSelection(product)
            @cart._showNoSelection(product)
          else
            ids = @cart._getSelectedCharacteristicIds(product)
            variantId = @cart._findVariantByCharacteristics(ids, variants)
            if variantId
              variant = @cart._getVariantData(product, variantId)
              @cart._enableAllSenders(product)
              @cart._showPrices(product, variant)
            else
              @cart._showNotAvailable(product)
        else
          variant = @cart._getDefaultVariantData(product)
          if variant
            @cart._enableAllSenders(product)
            @cart._showPrices(product, variant)


      _getVariantData: (product, variantId) =>
        for variant in @cart._discoverVariants(product)
          return variant if variant.id == variantId
        null

      _getDefaultVariantData: (product) =>
        for variant in @cart._discoverVariants(product)
          return variant if variant.default == true
        null

      _registerSelection: (product, option, characteristic) =>
        @cart._products[@cart._discoverValue(product, "product")][@cart._discoverValue(option, "option")] = characteristic.value

      _discoverVariants: (product) =>
        JSON.parse(@cart._discoverValue(product, "variants", "[]"))

      _discoverParent: (suffix, from) =>
        if from.getAttribute("data-wundery-#{suffix}")
          from
        else
          if from.parentNode
            @cart._discoverParent(suffix, from.parentNode)
          else
            null

      _discoverValue: (element, suffix, defaultValue = null) =>
        value = element.getAttribute("data-wundery-#{suffix}")
        if value
          value
        else
          defaultValue

      _discover: (suffix, within) =>
        selector = "[data-wundery-#{suffix}]"
        @_log "*** Discover #{selector}"
        found = within.querySelectorAll(selector)
        @_log "    => Found #{found.length} element(s)"
        found

      _hasPersistedCheckoutId: =>
        @_getCookie(@cart._cookieName()) != null

      _getPersistedCheckoutId: =>
        @_getCookie(@cart._cookieName())

      _cookieName: ->
        "wundery_checkout_id"

  # ////////////////////////////////////////////////////////////////////////////
  #
  # Public methods
  #
  # ////////////////////////////////////////////////////////////////////////////

  configure: (options) ->
    if options && options.loggingEnabled
      @_options.loggingEnabled = options.loggingEnabled
    if options && options.checkoutEndpoint
      @_options.checkoutEndpoint = options.checkoutEndpoint
    if options && options.apiEndpoint
      @_options.apiEndpoint = options.apiEndpoint
    this

  version: (option) ->
    return "1"

  # ////////////////////////////////////////////////////////////////////////////
  #
  # Internal methods
  #
  # ////////////////////////////////////////////////////////////////////////////

  _isLoggingEnabled: =>
    @_options.loggingEnabled == true

  _log: (message, option) ->
    if @_isLoggingEnabled()
      if option
        console.log message, option
      else
        console.log message

  _logError: (message) ->
    @_log "%c#{message}", "background-color: red; color: white"

  _setCookie: (name, value) =>
    date = new Date
    date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000))
    document.cookie = "#{name}=#{value}; expires=#{date.toGMTString()}; path=/"
    true

  _unsetCookie: (name) =>
    date = new Date
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000))
    document.cookie = "#{name}=; expires=#{date.toGMTString()}; path=/"
    true

  _getCookie: (name) =>
    return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(name).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null

  _xmlhttp: (callback) ->
    xmlhttp = new XMLHttpRequest()
    xmlhttp.onreadystatechange = ->
      if xmlhttp.readyState == 4
        callback(xmlhttp.status, xmlhttp.responseText)

    xmlhttp

  _request: (method, url, payload, callback) ->
    timestamp = "?timestamp=#{new Date().getTime()}"
    xmlhttp = @_xmlhttp(callback)
    xmlhttp.open(method, url + timestamp, true)
    xmlhttp.setRequestHeader('Content-type', 'application/json');
    xmlhttp.send(JSON.stringify(payload))
    true

  _checkoutEndpoint: (append) ->
    "#{@_options.checkoutEndpoint}/#{append}"

  _apiEndpoint: (append) ->
    "#{@_options.apiEndpoint}/#{append}"

  _arrayEqual: (a, b) ->
    if Object.prototype.toString.call(a) == '[object Array]' && Object.prototype.toString.call(b) == '[object Array]'
      a = a.sort()
      b = b.sort()
      a.length is b.length and a.every (elem, i) -> elem is b[i]
    else
      false

window.Wundery = new Wundery

